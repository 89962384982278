import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Web from './pages/web';
import LoginPage from './pages/login/Login';
import PlatformCallbackPage from './pages/login/PlatformCallbackPage';
import GoogleCallbackPage from './pages/login/GoogleCallbackPage';
import TaskDetailPage from './pages/task/TaskDetail';
import TaskDetailCheckUser from './pages/task/TaskDetailCheckUser';
import CampaignHome from './pages/campaign/Index';
import CampaignGuide from './pages/campaign/guide';
import CampaignLogin from './pages/campaign/login';
import CampaignLinked from './pages/campaign/linked';
import LoginError from './pages/campaign/loginError';
import CampaignDetail from './pages/campaign/detail';
import MyDelivery from './pages/campaign/delivery';
import CampaignPaymentMethod from './pages/campaign/paymentMethod';
import DraftDetail from './pages/campaign/draftDetail';
import UploadDraft from './pages/campaign/uploadDraft';
import UpdatePaymentMethod from './pages/campaign/updatePaymentMethod';
import ErrorPage from './pages/campaign/error';
import { isWebOnPc } from '@/biz/tool';
import { initCustomEvent } from './utils';
import './index.css';

const App: React.FC = () => {
  useEffect(() => {
    initCustomEvent();
  }, []);

  return (
    <div id="app" className={isWebOnPc() ? 'app-web-on-pc' : 'app-mobile'}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web" element={<Web />} />
        <Route path="/campaign/error" element={<ErrorPage />} />

        <Route path="/auth" element={<LoginPage />} />
        <Route path="/auth/google/callback" element={<GoogleCallbackPage />} />
        <Route
          path="/auth/:platform/callback"
          element={<PlatformCallbackPage />}
        />

        <Route path="/task/:id" element={<TaskDetailPage />} />
        <Route path="/task/checkuser/:id" element={<TaskDetailCheckUser />} />

        <Route path="/campaign/email/" element={<CampaignHome />} />
        <Route path="/campaign/home/:orderId" element={<CampaignGuide />} />
        <Route path="/campaign/login/" element={<CampaignLogin />} />
        <Route path="/campaign/linked/" element={<CampaignLinked />} />
        <Route path="/campaign/loginError/" element={<LoginError />} />
        <Route path="/campaign/detail/:orderId" element={<CampaignDetail />} />
        <Route path="/campaign/delivery/:orderId" element={<MyDelivery />} />
        <Route
          path="/campaign/payment/method"
          element={<CampaignPaymentMethod />}
        />
        <Route
          path="/campaign/draft/detail/:order_id"
          element={<DraftDetail />}
        />
        <Route
          path="/campaign/draft/upload/:method"
          element={<UploadDraft />}
        />
        <Route
          path="/campaign/payment/method/update/:type"
          element={<UpdatePaymentMethod />}
        />
      </Routes>
    </div>
  );
};

export default App;
